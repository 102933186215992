import React, { Fragment, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { SEO, TransactionalFirstSection, TransactionalSecondSection, StrategicGrowthThirdSection } from '../components';
import { SEO_TEXT } from '../constants';
import { SanityStrategicType } from '../utils/globalTypes';
import { getClient } from '../utils/sanity.client';
import { strategicGrowthQuery } from '../utils/querys/strategicGrowth/query';

interface Props {
  data: {
    sanityStrategicGrowth: SanityStrategicType;
  };
}

const StrategicGrowth = ({ data: { sanityStrategicGrowth } }: Props) => {
  const [strategicData, setStrategicData] = useState<any>();

  useEffect(() => {
    const url = new URL(location.href);
    const getData = async () => {
      const client = getClient({ token: process.env.GATSBY_SANITY_READ_TOKEN || '' });
      const data = await client.fetch(strategicGrowthQuery);
      setStrategicData(data);
    };
    if (strategicData === undefined && url.search === '?preview_mode=true') getData();
  }, [strategicData]);
  return (
    <Fragment>
      <SEO title={sanityStrategicGrowth.seo?.title || ''} description={sanityStrategicGrowth.seo?.description || ''} />
      <TransactionalFirstSection
        content={strategicData ? strategicData.firstSection : sanityStrategicGrowth.firstSection}
      />
      <TransactionalSecondSection
        content={strategicData ? strategicData.secondSection : sanityStrategicGrowth.secondSection}
      />
      <StrategicGrowthThirdSection
        content={strategicData ? strategicData.thirdSection : sanityStrategicGrowth.thirdSection}
      />
    </Fragment>
  );
};

export default StrategicGrowth;

export const pageQuery = graphql`
  query {
    sanityStrategicGrowth {
      seo {
        title
        keywords
        description
      }
      firstSection {
        title
        cateogry
      }
      secondSection {
        title
        _rawDescription
        mainImage {
          asset {
            gatsbyImageData(fit: CROP)
          }
        }
      }
      thirdSection {
        title
        subTitle
        _rawDescription
      }
    }
  }
`;
